import React from 'react';

interface ArticleAuthorProps {
  authors: string;
}
export default function ArticleAuthor({authors}: ArticleAuthorProps) {
  const authorsNames = authors.replace(',', '').split('&');
  return (
    <>
      {authorsNames.map((authorName, index) => (
        <React.Fragment key={authorName}>
          <span
            itemProp="author"
            itemScope
            itemType="https://schema.org/Person"
          >
            <meta
              itemProp="url"
              content={`https://cdn.shopify.com/s/files/1/0840/8370/3830/files/159308894-${authorName}.png`}
            />
            <span itemProp="name">{authorName}</span>
          </span>
          {index !== authorsNames.length - 1 && <> & </>}
        </React.Fragment>
      ))}
    </>
  );
}
